'use client'

import FlocklerEmbed from '@flockler/react-flockler-embed'
import { ComponentType } from 'react'

const FlockerWidget: ComponentType = () => (
  <section className="container-white">
    <FlocklerEmbed
      siteUuid="1744e62ed2b0d583059b8799660ea2bc"
      embedUuid="188525950a607ddfccf5ab78a300174d"
    />
  </section>
)

export default FlockerWidget
