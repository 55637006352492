'use client'

import { ComponentType } from 'react'
import { isTabletScreen } from 'shared/domHelpers'

const FanqWidget: ComponentType = () => {
  const isTablet = isTabletScreen()

  if (!isTablet) {
    return
  }

  return (
    <iframe
      src="https://widget.fanq-app.com/fanq/build/frame.htm?wid=337b2f95-6116-4496-bdff-0d7b93f2ede8&style=dhb"
      id="fq_iframe"
      className="fq_iframes"
      scrolling="no"
      frameBorder="0"
      style={{
        width: 'clamp(300px,100%,100%)',
        height: '525px'
      }}
    />
  )
}

export default FanqWidget
