import React, { ComponentType } from 'react'
import { News } from 'types'
import Image, { maxScreenSize } from 'components/ui/basic/Image'
import { Heading } from 'components/ui/text/Heading'
import { videocenterPath } from 'shared/urlHelpers'
import Link from 'components/router/Link'
import { checkImage } from 'shared/imageHelpers'
import NewsMeta from './NewsMeta'
import { PlayCircle } from 'react-feather'
import { isTabletScreen } from 'shared/domHelpers'

type NewsItemProps = {
  news: News
  video?: boolean
  withMeta?: boolean
  priority?: boolean
}

export const NewsItemVertical: ComponentType<NewsItemProps> = ({
  news,
  video,
  withMeta = true,
  priority = false
}) => {
  const isTablet = isTabletScreen()
  return (
    <>
      {isTablet
        ? (
        <Link
          href={videocenterPath(news)}
          className="w-full flex items-start hover:text-current"
        >
          <div className="w-1/4 min-w-1/4">
            <div className="flex items-center justify-center relative w-full h-full">
              <Image
                src={checkImage(news.feature_image)}
                className="w-full h-full rounded overflow-hidden shadow aspect-w-4 aspect-h-3"
                srcSet={[[300, maxScreenSize]]}
                priority={priority}
              />
              {video && (
                <PlayCircle size="2em" className="absolute shadow-lg" />
              )}
            </div>
          </div>
          <div className="view-vertical w-3/4 md:w-10/12 pl-4 pr-4">
            <NewsMeta news={news} />

            <Heading
              level={4}
              weight="medium"
              size="base"
              className="line-clamp-4"
            >
              {news.title}
            </Heading>
          </div>
        </Link>
          )
        : (
        <Link href={videocenterPath(news)} className="hover:text-current">
          <div className="view-vertical">
            <div className="relative flex items-center justify-center">
              <Image
                src={checkImage(news.feature_image)}
                className="w-full h-full rounded overflow-hidden shadow aspect-w-16 aspect-h-9"
                srcSet={[[300, maxScreenSize]]}
                priority={priority}
              />
              {video && (
                <PlayCircle size="3em" className="absolute shadow-lg" />
              )}
            </div>
            <div className="view-vertical">
              <NewsMeta news={news} className="my-4" />
              <Heading
                level={4}
                weight="medium"
                size="base"
                className="line-clamp-3"
              >
                {news.title}
              </Heading>
            </div>
          </div>
        </Link>
          )}
    </>
  )
}

export const NewsItemHorizontal: ComponentType<NewsItemProps> = ({
  news,
  video,
  priority = false
}) => {
  return (
    <Link
      href={videocenterPath(news)}
      className="w-full flex items-start hover:text-current"
    >
      <div className="w-1/4 min-w-1/4">
        <div className="flex items-center justify-center relative w-full h-full">
          <Image
            src={checkImage(news.feature_image)}
            className="w-full h-full rounded overflow-hidden shadow aspect-w-4 aspect-h-3"
            srcSet={[[200, maxScreenSize]]}
            priority={priority}
          />
          {video && <PlayCircle size="2em" className="absolute shadow-lg" />}
        </div>
      </div>
      <div className="view-vertical w-3/4 md:w-10/12 pl-4 pr-4">
        <NewsMeta news={news} />

        <Heading level={4} weight="medium" size="base">
          {news.title}
        </Heading>
      </div>
    </Link>
  )
}

const NewsList: ComponentType<{
  items: News[]
  type?: 'vertical' | 'horizontal'
}> = ({ items, type = 'horizontal' }) => (
  <div className="grid sm:grid-cols-2 gap-8">
    {items.map((item) =>
      type === 'vertical'
        ? (
        <NewsItemVertical key={item.id} news={item} />
          )
        : (
        <NewsItemHorizontal key={item.id} news={item} />
          )
    )}
  </div>
)

export default NewsList
