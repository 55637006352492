'use client'

import React, { ComponentType } from 'react'
import Link from 'components/router/Link'
import { TournamentTable } from 'types'
import { teamPath } from 'shared/urlHelpers'
import Image from 'components/ui/basic/Image'
import checkLogo from 'shared/imageHelpers'
import { formatNumber } from 'shared/timeHelper'

export type TableType = 'Main' | 'Home' | 'Away' | 'Cross'

export type TableProps<T> = {
  table: T
  hint?: string
  limit?: number
  noBanner?: boolean
}

const Table: ComponentType<TableProps<TournamentTable>> = ({
  table,
  hint,
  limit
}) => {
  let showedItems = table.rows
  if (limit) {
    showedItems = table.rows.slice(0, limit)
  }

  if (table.rows.length === 0) {
    return <p>Es liegen noch keine Tabellendaten vor!</p>
  }

  return (
    <>
      {table.header && (
        <p className="text-xs text-gray-500 prose formatted-text m-2">
          {table.header}
        </p>
      )}

      <table className={`table ${hint ? 'mb-4' : ''}`}>
        <thead>
          <tr>
            <th className="text-right">Pl.</th>
            <th></th>
            <th className="text-left">Team</th>
            <th className="text-right">Sp.</th>
            <th className="text-right">Tore</th>
            <th className="text-right">Pkt.</th>
          </tr>
        </thead>
        <tbody>
          {showedItems.map((row, i) => (
            <tr key={i} data-tooltip-content={row.team.name}>
              <td className="text-right numeric font-bold">{row.rank}</td>
              <td>
                <Link href={teamPath(row.team)}>
                  <Image
                    className="w-5 h-5"
                    src={checkLogo(row.team.logo, 'club')}
                    alt={`Logo ${row.team.name}`}
                    objectFit="contain"
                  />
                </Link>
              </td>
              <td className="truncate">
                <Link href={teamPath(row.team)} className="w-full">
                  {row.team.name}
                </Link>
              </td>
              <td className="text-right numeric font-bold">{row.games}</td>
              <td className="text-right numeric font-bold">
                {formatNumber(row.goals)}:{formatNumber(row.goalsAgainst)}
              </td>
              <td className="text-right numeric font-bold">{row.points}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {table.footer && (
        <p className="text-xs text-gray-500 prose formatted-text m-2">
          {table.footer}
        </p>
      )}

      {hint && (
        <p className="text-xs text-gray-500 prose m-2 whitespace-pre-line">
          {hint}
        </p>
      )}
    </>
  )
}

export default Table
