'use client'
import React, { ComponentType } from 'react'
import { Heading } from 'components/ui/text/Heading'

import { rootPath, whySignUpPath } from 'shared/urlHelpers'

import Link from 'components/router/Link'
import LinkButton from 'components/router/LinkButton'
import { useSession } from 'lib/session-client'

export const WhyRegister: ComponentType = () => {
  const [session] = useSession()

  if (!session) {
    return
  }
  return (
    <section
      id="whyRegister"
      className="view-vertical container-white items-stretch"
    >
      <>
        <Heading level={2} size="lg" border className="w-full mb-4">
          ALLE TEAMS. EINE LIEBE. EINE APP.
        </Heading>
        <div className="mb-4">
          <p className="mb-1">
            <strong>
              Das Spieldatenportal{' '}
              <Link href={rootPath()} className="whitespace-nowrap btn-link">
                handball.net
              </Link>{' '}
              ist die neue digitale Heimat des Handballs
            </strong>
          </p>
          <p className="mb-4">
            Wie steht&apos;s in der Landesliga? Was macht die Kreisklasse? Und
            wer spielt gerade in den Bundesligen? Was bisher zu einer
            strapaziösen Reise durchs Web geriet, wird mit{' '}
            <Link href={rootPath()} className="whitespace-nowrap btn-link">
              handball.net
            </Link>{' '}
            ein digitaler Spaziergang. Alle Teams. Eine Liebe. Eine App. Fans
            können{' '}
            <Link href={rootPath()} className="whitespace-nowrap btn-link">
              handball.net
            </Link>{' '}
            kostenfrei über jedes Endgerät nutzen – von der klassischen
            Desktop-Variante bis hin zur Smartphone-App.
          </p>
          <p>
            Egal, welcher Weg gewählt wird: Mit einem persönlichen Profil kann{' '}
            <Link href={rootPath()} className="whitespace-nowrap btn-link">
              handball.net
            </Link>{' '}
            exakt dem eigenen Bedarf angepasst werden, so dass es von der
            Startseite mit nur einem Klick direkt zu den aktuellen Spielen der
            favorisierten Teams geht.
          </p>
        </div>
        <LinkButton
          href={whySignUpPath()}
          className="grid justify-items-center mb-2"
        >
          Mache handball.net zu Deiner Seite - jetzt&nbsp;registrieren!
        </LinkButton>
      </>
    </section>
  )
}
