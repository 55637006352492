import React, { ComponentType } from 'react'
import { formattedDateFullShort } from 'shared/timeHelper'
import { News } from 'types'

type MetaProps = {
  news: News
  className?: string
}

const NewsMeta: ComponentType<MetaProps> = ({ news, className = '' }) => {
  // TODO : needs some mapping logic
  const owner = news.primary_tag || news.tags[0]

  return (
    <div className={`view-horizontal items-center mb-2 ${className}`}>
      {owner && (
        <>
          {owner.name && (
            <span className="truncate text-sm font-semnibold">
              {owner.name}
            </span>
          )}
        </>
      )}
      {news.published_at && (
        <>
          {owner && <span className="text-gray-400 mx-1 text-sm">|</span>}
          <span className="text-gray-400 whitespace-nowrap text-sm">
            {formattedDateFullShort(news.published_at)}
          </span>
        </>
      )}
    </div>
  )
}

export default NewsMeta
